
import {
  defineComponent,
  reactive,
  toRefs,
  watch,
  onBeforeMount,
  onMounted,
} from 'vue';
import {onBeforeRouteUpdate, useRoute, useRouter} from 'vue-router';
import contentDetail from '@/components/page/contentDetail.vue';
import keynoteDetail from "@/components/page/keynoteDetail.vue";
import imgBox from '@/components/page/imgBox.vue';
import axios from '@/api/http.js';
import {ElLoading, ElMessageBox} from 'element-plus';

export default defineComponent({
  name: 'Content',
  components: {
    contentDetail,
    keynoteDetail,
    imgBox,
  },
  setup() {
    const client = localStorage.getItem("eventClient");
    const route = useRoute();
    const router = useRouter();

    // const name = route.query.name;
    const data = reactive({
      showTitle: false,
      contents: [
        // {
        //   // img: require("../../assets/imgs/hulk.jpg"),
        //   img: require('../../assets/imgs/favorite.jpg'),
        //   id: 1,
        //   name: 'VMWARE TANZU',
        //   description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Et obcaecati, facere iusto eos doloribus, unde ut explicabo cumque facilis ipsam nisi libero consect',
        // },
        // {
        //   img: require('../../assets/imgs/favorite.jpg'),
        //   id: 2,
        //   name: 'VMWARE TANZU',
        //   description:
        //       'Lorem ipsum dolor sit amet consectetur adipisicing elit. Et obcaecati, facere iusto eos doloribus, unde ut explicabo cumque facilis ipsam nisi libero consect',
        // },
        // {
        //   img: require('../../assets/imgs/favorite.jpg'),
        //   id: 3,
        //   name: 'VMWARE TANZU',
        //   description:
        //       'Lorem ipsum dolor sit amet consectetur adipisicing elit. Et obcaecati, facere iusto eos doloribus, unde ut explicabo cumque facilis ipsam nisi libero consect',
        // },
        // {
        //   img: require('../../assets/imgs/favorite.jpg'),
        //   id: 4,
        //   name: 'VMWARE TANZU',
        //   description:
        //       'Lorem ipsum dolor sit amet consectetur adipisicing elit. Et obcaecati, facere iusto eos doloribus, unde ut explicabo cumque facilis ipsam nisi libero consect',
        // },
        // {
        //   img: require('../../assets/imgs/favorite.jpg'),
        //   id: 5,
        //   name: 'VMWARE TANZU',
        //   description:
        //       'Lorem ipsum dolor sit amet consectetur adipisicing elit. Et obcaecati, facere iusto eos doloribus, unde ut explicabo cumque facilis ipsam nisi libero consect',
        // },
      ],
      name: route.query.name,
      id: route.query.id,
      showDetail: false,
      detailData: [],//详细信息
    });
    const goRelated = (data) => {
      // getData(data.id)
      router.push({
        name: 'Content',
        query: {id: data.id},
      });
    };
    const getData = (id = '') => {
      axios.request({
        method: 'PATCH',
        url: '/virtual/agenda_detail',
        data: {
          meetingId: 'a7yyxczuty0qpjo2393gmyad',
          // meetingId: localStorage.eventsMeetingId,
          agendaId: id || data.id,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }
      }).then(res => {
        console.log("====res!!! =====", res)
        if (res.data.result == '500') {
          ElMessageBox.alert(res.data.error, '', {
            confirmButtonText: 'OK',
            callback: () => {
              const loading = ElLoading.service({
                lock: true,
              })
              router.push({name: 'Home'});
              loading.close();
            }
          })
          return
        }
        data.showTitle = true;
        data.detailData = res.data.data;
        data.contents = res.data.data.ralate_contents;
        data.contents = data.contents.splice(0, 6);//最多显示6个
        data.showDetail = true;
        console.log("==== route=====", route.query.id)
        // data.showDetail =true;
      }).catch(err => {
        console.log(err);
      });
    };
    getData();
    const refData = toRefs(data);
    onBeforeRouteUpdate((to: any) => {
      const loading = ElLoading.service({
        lock: true,
      })
      let id = to.query.id;
      axios.request({
        method: 'PATCH',
        url: '/virtual/agenda_detail',
        data: {
          meetingId: 'a7yyxczuty0qpjo2393gmyad',
          // meetingId: localStorage.eventsMeetingId,
          agendaId: id,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }
      }).then(res => {
        if (res.data.result == '500') {
          ElMessageBox.alert(res.data.error, '', {
            confirmButtonText: 'OK',
            callback: () => {
              const loading = ElLoading.service({
                lock: true,
              })
              router.push({name: 'Home'});
              loading.close();
            }
          })
          return
        }
        data.showTitle = true;
        data.detailData = res.data.data;
        data.contents = res.data.data.ralate_contents;
        data.contents = data.contents.splice(0, 6);//最多显示6个
        data.showDetail = true;
        console.log("==== route=====", route.query.id)
        loading.close();
        // data.showDetail =true;
      }).catch(err => {
        loading.close();
        console.log(err);
      });
    });
    return {
      ...refData,
      goRelated,
      route,
      client,
      router
    };
  },
});
