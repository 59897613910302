import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8b08bf1a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "navContentThird pagePadding" }
const _hoisted_2 = {
  key: 1,
  class: "navContentThirdBottom"
}
const _hoisted_3 = { class: "pageSubTitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_keynoteDetail = _resolveComponent("keynoteDetail")!
  const _component_imgBox = _resolveComponent("imgBox")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createTextVNode(_toDisplayString(_ctx.data), 1),
    (_ctx.showDetail)
      ? (_openBlock(), _createBlock(_component_keynoteDetail, {
          key: 0,
          style: {"margin-top":"10px"},
          data: _ctx.detailData
        }, null, 8, ["data"]))
      : _createCommentVNode("", true),
    (_ctx.contents.length > 0)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
          _withDirectives(_createElementVNode("h1", _hoisted_3, "RECOMMENDED CONTENT", 512), [
            [_vShow, _ctx.showTitle]
          ]),
          _createVNode(_component_el_row, {
            class: "imgsContainer",
            gutter: 20
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contents, (item) => {
                return (_openBlock(), _createBlock(_component_el_col, {
                  xs: 24,
                  md: 4,
                  lg: 4,
                  key: item
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_imgBox, {
                      showBottomTitle: true,
                      showBottomText: false,
                      style: {"cursor":"pointer"},
                      data: item,
                      flexable: true,
                      onClick: ($event: any) => (_ctx.goRelated(item))
                    }, null, 8, ["data", "onClick"])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          })
        ], 512)), [
          [_vShow, _ctx.client!='inpixon']
        ])
      : _createCommentVNode("", true)
  ]))
}